import { IconContext } from "react-icons";
import { BiSolidLabel } from "react-icons/bi";

import Header from "./components/Header";
import ImgDisplay from "./components/ImgDisplay";

import "./finChair.css";
function FinChair() {
  return (
    <IconContext.Provider value={{ className: "finChairIcon" }}>
      <div className="finChair">
        <Header
          title={"Finchair"}
          subheader={
            "A credit motivation app which rewards its users for paying their bills and debt on time."
          }
          company={"Finchair"}
          location={"London"}
          stakeholder={"Chaitanya Talhar"}
        />
        <main className="caseStudyMain">
          <h4>Brief</h4>
          <p>
            Our focus is on combining debt and credit card payments into one
            convenient platform. It's essential that our app is user-friendly,
            as our objective is to streamline the market and help people manage
            their finances more effectively. To accomplish this, we're aiming to
            encourage positive financial behavior by offering attractive
            rewards. Instead of the usual cash back and coupons, we're exploring
            innovative approaches to keep users engaged with our app
            consistently. Do you have any creative ideas that go beyond the
            traditional concepts of discounts and cash back?
          </p>
          <img
            src="images/finChair0.png"
            alt="brainstorm"
            className="briefImage"
          />
          <h4>Problem</h4>
          <p>
            When individuals possess multiple credit cards from different banks,
            it can occasionally result in an unfavorable financial situation.
            Managing finances and EMIs through various banks can be challenging
            to stay on top of, often resulting in missed payments and deadlines.
            This lack of organization can impact a person's credit score and
            exacerbate their financial situation.
          </p>
          <h4>Solution</h4>
          <p>Create a mobile app that wil</p>
          <ul className="listStyle">
            <BiSolidLabel />
            <li>
              Link all accounts together so that a user will see a total
              overview of all their finances: money, bills & payments.
            </li>
            <BiSolidLabel />
            <li>
              Incentivise users by rewarding good financial behaviour: e.g.
              paying bills on time will earn a user rewards
            </li>
            <BiSolidLabel />
            <li>
              Incorporate AI: bringing attention to key things such as red
              flags, charges, overpayments as well as providing tips to help
              with a user's financial knowledge and in turn increase credit
              score.
            </li>
          </ul>
          <h4>Discussion Guide</h4>
          <p>
            To prepare for the initial stakeholder meeting we as a group
            brainstormed key areas and topics from the brief that we felt was
            important to get clarification on in order to start our research
            process. Here are some of the more important questions we asked:
          </p>
          <ul className="listStyle">
            <BiSolidLabel />
            <li>
              <b>What is your business model?</b>
            </li>
            <li>
              The client's business model revolves around the network, such as
              affiliations, commissions with brands, credit availability,
              extension of credit and short term lending. The users and their
              data are valued and wants to use their data not to sell but to
              leverage the community.
            </li>
            <BiSolidLabel />
            <li>
              <b>Who is your target audience?</b>
            </li>
            <li>
              The client's ideal target audience is individuals who use 1 or
              more credit cards, are renting a property and/or someone who has
              some sort of debt. They want Finchair to be for everyone
              regardless of their credit score.
            </li>
            <BiSolidLabel />
            <li>
              <b>How fun would you like your product to be?</b>
            </li>
            <li>
              The client had a big emphasis on the app being very fun and
              gamified. They expressed that they didn't want Finchair to just be
              another financial technology app but rather categorised as a
              lifestyle app.
            </li>
            <BiSolidLabel />
            <li>
              <b>What is the long term success of this product?</b>
            </li>
            <li>“Having a big community that benefits from something good”</li>
          </ul>
          <div className="containerBlue">
            <ImgDisplay
              className={"imgDisplay"}
              id={"brainstorm"}
              title={"Brainstorm"}
              intro={
                "After the stakeholder meeting, we conducted a brainstorming session to visualize key words that encapsulate what we learned about Finchair."
              }
              src={"/images/finChair4.png"}
              alt={"brainstorm"}
            />
            <ImgDisplay
              className={"imgDisplay"}
              id={"moodboard"}
              title={"Moodboard"}
              intro={
                "The purpose of the mood board is to visually communicate concepts centred around practising sound financial behaviour, achieving financial goals, and fostering a sense of community."
              }
              src={"/images/finChair3.jpg"}
              alt={"moodboard"}
            />
          </div>

          <h4>Competitors</h4>
          <h5>Clearscore</h5>
          <p>
            The app's layout maintains consistency and organizes data in an
            aesthetically pleasing manner. The summary and report are
            intuitively placed for easy access, while data visualization remains
            clear and harmonious with other page elements. However, the payment
            history section appears somewhat crowded, and there's an abundance
            of information condensed within a single page.
          </p>
          <img
            src="/images/clearscore.jpg"
            alt="clearscore user interface"
            className="competitors"
          />
          <h5>Credikarma</h5>
          <p>
            The design showcases strong consistency and an appealing layout.
            Overlay components effectively notify the user, and illustrations
            are used to convey messages. The app offers personalized rewards
            based on the user's score.
          </p>
          <img
            src="/images/creditkarma.jpg"
            alt="credikarma user interface"
            className="competitors"
          />
          <h5>Gohenry</h5>
          <p>
            The design embodies vibrancy and color, effectively appealing to the
            target audience, while conveying a delightful personality and tone.
            Notifications ensure you stay well-informed, enhancing usability and
            comprehension. The user balance is clearly displayed on the page,
            and color-coded bars amplify the perception of progress.
          </p>
          <img
            src="/images/gohenry.jpg"
            alt="gohenry user interface"
            className="competitors"
          />
          <h4>Quantitative research</h4>
          <p>
            In order to define our core problem, our next step was to conduct
            user research on potential users. Our main goal was to define our
            problem and discover user pain points.
          </p>
          <p>
            We started with quantitative research, sending out screener surveys
            to friends, family to understand people's financial habits and more
            particularly, how people with multiple outgoings manage their
            finances.
          </p>
          <p>
            We were looking for individuals ages 18-60, with one or more credit
            cards and EMIs, who were paying rent or mortgages.
          </p>
          <p>
            <b>Here are some of the key takeaways based on 103 responses:</b>
          </p>
          <h4>Survey:</h4>
          <div className="quantitativeData">
            <img
              src="/images/survey/Artboard1.svg"
              alt="survey 1"
              className="quantitative"
            />
            <img
              src="/images/survey/Artboard2.svg"
              alt="survey 2"
              className="quantitative"
            />
            <img
              src="/images/survey/Artboard3.svg"
              alt="survey 3"
              className="quantitative"
            />
            <img
              src="/images/survey/Artboard4.svg"
              alt="survey 4"
              className="quantitative"
            />
            <img
              src="/images/survey/Artboard5.svg"
              alt="survey 5"
              className="quantitative"
            />
            <img
              src="/images/survey/Artboard6.svg"
              alt="survey 6"
              className="quantitative"
            />
            <img
              src="/images/survey/Artboard7.svg"
              alt="survey 7"
              className="quantitative"
            />
            <img
              src="/images/survey/Artboard8.svg"
              alt="survey 8"
              className="quantitative"
            />
            <img
              src="/images/survey/Artboard9.svg"
              alt="survey 9"
              className="quantitative"
            />
            <img
              src="/images/survey/Artboard10.svg"
              alt="survey 10"
              className="quantitative"
            />
          </div>
          <h4>Qualitative research</h4>
          <p>
            To gather more detailed information, we conducted qualitative
            research by carrying out one-on-one interviews with over 40
            individuals. This approach allowed us to delve deeper into the
            subject matter and gain a more comprehensive understanding of the
            topic at hand.
          </p>
          <p>
            We posed questions concerning their present financial status,
            general behavioral patterns, habits, as well as their financial and
            personal aspirations.
          </p>
          <p>
            <b>For example:</b>
          </p>
          <ul className="listStyle">
            <BiSolidLabel />
            <li>
              Where do you take your financial advice from? Do you trust it?
            </li>
            <BiSolidLabel />
            <li>
              Are you conscious of your credit rating? Explain your thoughts
              towards credit ratings.
            </li>
            <BiSolidLabel />
            <li>Do you use any apps to manage your money? Which ones?</li>
            <BiSolidLabel />
            <li>
              What are your biggest frustrations with managing your finances?
            </li>
            <BiSolidLabel />
            <li>
              Do you take full advantage of all the deals, discounts and coupons
              available to you? If not, why?
            </li>
            <BiSolidLabel />
            <li>What's your day to day spending/yearly spending</li>
          </ul>
          <p>
            <b>
              We documented essential quotes, observations, and behaviours,
              resulting in over 100 data points.
            </b>
          </p>
          <p>
            We subsequently synthesized these into key trends using an affinity
            map.
          </p>
          <p>
            <b>Key takeaways:</b>
          </p>
          <ul className="qualitativeFinding">
            <BiSolidLabel />
            <li>
              We found a huge factor for financial incentives was family or
              travel.
            </li>
            <BiSolidLabel />
            <li>
              Most people would like to learn more about finance and create
              healthier financial habits but find it difficult to understand and
              hard to access information that is easy to process.
            </li>
            <BiSolidLabel />
            <li>Most people's financial goal is to own a house.</li>
            <BiSolidLabel />
            <li>
              People like playing competitive games with friends due to their
              competitive nature.
            </li>
            <BiSolidLabel />
            <li>
              People use rewards and discounts for things they are already going
              to buy.
            </li>
            <BiSolidLabel />
            <li>
              People like grocery shopping discounts- tesco clubcard and M&S
              sparks card.
            </li>
            <BiSolidLabel />
            <li>
              People don't have time to play games - dont like playing games on
              ther phone - or only to kill time.
            </li>
          </ul>

          <p>
            <b>Pain points:</b>
          </p>
          <ul className="qualitativeFinding">
            <BiSolidLabel />
            <li>
              People are scared of credit ratings- difficult to understand how
              they are calculated and difficult to understand what they can do
              to change them- not sure how they can change them.
            </li>
            <BiSolidLabel />
            <li>
              People don't like how their past mistakes when they were younger
              affect their credit ratings.
            </li>
            <BiSolidLabel />
            <li>
              Frustrated that things outside your control- like market
              conditions- can affect your financial health.
            </li>
            <BiSolidLabel />
            <li>
              Hard to control budgets - especially with social aspects-b like
              going out with friends.
            </li>
            <BiSolidLabel />
            <li>Doesn't know how to invest her money-risky?.</li>
            <BiSolidLabel />
            <li>Doesn't understand taxes.</li>
            <BiSolidLabel />
            <li>
              People find it hard to manage and use all the discounts available
              to them
            </li>
          </ul>
          <p>
            <b>Quote:</b>
          </p>
          <ul className="qualitativeFinding">
            <BiSolidLabel />
            <li>"Credit rating are unfair"</li>
            <BiSolidLabel />
            <li>"Im too scared to check my credit rating"</li>
            <BiSolidLabel />
            <li>"I have manageable debt"</li>
            <BiSolidLabel />
            <li>
              "Debt is a financial insecurity- when you have no means of paying
              it back"
            </li>
            <BiSolidLabel />
            <li>"The reward needs to be good enough"</li>
          </ul>
          <h4>Persona</h4>
          <p>
            After synthesising all of the data (qualitative and quantitative)
            gathered from surveys and user interviews, using the Affinity Map
            technique, we found ourselves with eight personas:
          </p>
          <ul className="listStyle">
            <BiSolidLabel />
            <li>Jet Setter</li>
            <BiSolidLabel />
            <li>Party Animal</li>
            <BiSolidLabel />
            <li>Early Adopter</li>
            <BiSolidLabel />
            <li>Penny Pincher</li>
            <BiSolidLabel />
            <li>Struggling Student or Grad</li>
            <BiSolidLabel />
            <li>Young Professional</li>
            <BiSolidLabel />
            <li>Breadwinner</li>
            <BiSolidLabel />
            <li>Successful Middle Class</li>
          </ul>
          <div className="personaCreation">
            <img
              src="./images/finChair5.png"
              alt="persona creation"
              id="personaCreation"
            />
            <img
              src="./images/finChair6.png"
              alt="persona white board"
              id="personaWhiteboard"
            />
            <img
              src="./images/finChair7.png"
              alt="persona diagram"
              id="personaDiagram"
            />
          </div>
          <ul className="listStyle">
            <BiSolidLabel />
            <li>Jet Setter / Party Animal / Early Adopter</li>
            <BiSolidLabel />
            <li>Penny Pincher / Struggling Student or Grad</li>
            <BiSolidLabel />
            <li>Young Professional / Breadwinner / Successful Middle Class</li>
          </ul>
          <p>
            The primary persona who would benefit most from FinChair was the
            'Jet Setter.' We decided to focus our time on this persona due to
            our previously mentioned time constraints. The information about the
            secondary personas remains useful, but in order to prioritize
            features for launch and investment, we had to concentrate on the
            segment with the strongest use case.
          </p>
          <img
            src="./images/finChair8.png"
            alt="jet setter profile"
            id="jetSetter"
          />
          <h4>Empathy map</h4>
          <p>
            Knowing more about our users, we are now able to step into their
            shoes and see things through their eyes. We can empathise with their
            feelings and record things from their perspective.
          </p>
          <p>
            The empathy map will remain a good reference point throughout the
            design process. We can keep returning to it to understand what the
            user might say, do, feel, or think about our design choices.
          </p>
          <img
            src="./images/finChair9.png"
            alt="jet setter empathy map"
            id="empathyMap"
          />
          <h4>Problem statement</h4>
          <p>
            We summarised different problems statements based on different
            personas and finally sorted out a more general problem statement.
          </p>
          <img
            src="./images/finChair10.png"
            alt="jet setter problem statement"
            id="problemStatement"
          />
          <p>
            <b>
              Jet Setter needs a way to monitor their spending, so they will be
              able to achieve financial stability in the long run.
            </b>
          </p>
          <p>
            We will believe that creating a wayfor them to keep track of their
            spending will help them to start saving. We will see this to be true
            when they are more confident with their spending decision which will
            result in reducing debt and increase in saving
          </p>
          <h4>Idea generation workshop</h4>
          <p>
            <b>
              Crazy 8 has been used as a creative technique for idea generation.
            </b>{" "}
            It involves generating a variety of ideas quickly within a limited
            timeframe.
          </p>
          <p>
            The chosen idea was based on saving-pots created from the user to
            pursue specific saving goals. The app will also provide credit score
            details, expanses and incoming records.
          </p>
          <div className="creazy8">
            <img
              src="./images/finChair16.png"
              alt="problem statement"
              id="grid1"
            />
            <img src="./images/finChair15.png" alt="group meeting" id="grid2" />
            <img src="./images/finChair12.png" alt="stakeholder" id="grid3" />
            <img src="./images/finChair13.png" alt="crazy8" id="grid4" />
            <img src="./images/finChair11.png" alt="pick" id="grid5" />
            <img src="./images/finChair14.png" alt="choice" id="grid6" />
          </div>
          <h4>Storyboard</h4>
          <p>
            At this stage of the project, the group split, and each of us
            individually selected ideas that were generated during the workshop.
            Based on my chosen idea, I created a storyboard to aid in
            visualizing, communicating, and refining the project.
          </p>
          <img src="./images/finChair17.png" alt="storyboard" id="storyBoard" />
        </main>
      </div>
    </IconContext.Provider>
  );
}
export default FinChair;
