import "./imgDisplay.css";

function ImgDisplay(props) {
  return (
    <div id={props.id} className="imgDisplay">
      <div className="imageContainer">
        <img src={props.src} alt={props.alt} className={props.className} />
      </div>
      <div className="infoContainer">
        <h4>{props.title}</h4>
        <p>{props.intro}</p>
      </div>
    </div>
  );
}

export default ImgDisplay;
