import "./header.css";

function Header(props) {
  return (
    <header className="caseStudyHeader">
      <h3 className="caseStudyTitle">{props.title}</h3>
      <p className="caseStudySubHeader">{props.subheader}</p>
      <ul className="brifInfo">
        <li>
          <span className="left">Company:</span>
          <span className="right">{props.company}</span>
        </li>
        <li>
          <span className="left">Location:</span>
          <span className="right">{props.location}</span>
        </li>
        <li>
          <span className="left">Stakeholder:</span>
          <span className="right">{props.stakeholder}</span>
        </li>
      </ul>
    </header>
  );
}

export default Header;
