import { IconContext } from "react-icons";
import { BiSolidLabel } from "react-icons/bi";

import Header from "./components/Header";
// import ImgDisplay from "./components/ImgDisplay";

import "./fimpli.css";

function Fimpli() {
  return (
    <IconContext.Provider value={{ className: "finChairIcon" }}>
      <div className="flimpi">
        <Header
          title={"Flimpi"}
          subheader={
            "The mobile platform that offers geospatial marketing features to increase foot traffic and engagement for businesses and their tenants."
          }
          company={"Flimpi"}
          location={"London"}
          stakeholder={"Yousef Skandari"}
        />
      </div>
      <main className="caseStudyMain">
        <h4>Brief</h4>
        <p>
          Flimpi's objectives are to provide a reliable interactive platform
          that adapts to the wants and needs of key logo brands while increasing
          revenue for businesses through greater foot traffic and engagement.
          The company is currently in the growth stage with plans to expand its
          user base and introduce new features.
        </p>
        <h5>Foot Traffic</h5>
        <p>
          Without sufficient foot traffic businesses in in-person industries
          risk failure. Flimpi gives businesses the ability to orchestrate and
          time foot traffic by incentivising flows to certain locations at
          certain times.
        </p>
        <h5>Revenue</h5>
        <p>
          Increased foot traffic alone helps drive new revenues for businesses.
          However, Flimpi provides businesses the means to expose more consumers
          in a specific area, and at specific times to tailored promotions and
          advertisements thanks to our geospatial features. This is designed to
          generate trackable data that businesses can analyse to derive
          actionable insights for their marketing campaigns.Customer Engagement.
        </p>
        <h5>Customer Engagement</h5>
        <p>
          In-person industries typically use legacy marketing tools like out of
          house &#40;OOH&#41; marketing and online marketing. However, OOH and
          Online Marketing are not interactive or engaging for consumers and are
          often dismissed. Flimpi allows businesses to provide communities of
          consumers with relevant, timely, gamified marketing offers that they
          are far more likely to engage with.
        </p>
        <h5>Network Effects</h5>
        <p>
          Flimpi gives businesses visibility over the marketing and consumer
          activities of a wide ecosystem of stakeholders. Businesses can
          therefore tailor the scope and timing of their marketing campaigns to
          benefit from complementarities. In this way, businesses can generate
          new revenues from the network effects created on the Flimpi platform,
          while communities of consumers benefit by receiving more relevant and
          timely offers.
        </p>
        <h4>How Flimpi Changes Marketing</h4>
        <h5>Hexagonal Indexing</h5>
        <p>
          We web the globe with hexagons in order to create uniform publishing
          areas where businesses and other entities can post their content for
          users to traverse and obtain through our geo specific features.
        </p>
        <h5>Interactive OOH</h5>
        <p>
          Developing on top of our first pillar “Hexagonal Indexing” we created
          many interactive marketing tools which are rooted in geospatial
          attributes. &#40;E.g., treasure hunts, geo-raffles, exclusivity&#41;
        </p>
        <h5>Incentives</h5>
        <p>
          In order to drive user activity we have designed many incentive models
          to encourage users to share or view OOH media &#40;E.g., Flimpi
          Points, Leaderboards, Profile achievements&#41;
        </p>
        <h4>Design challenge</h4>
        <p>
          Being able to provide a product that is intuitive enough to grasp
          without the need for an explanation. Since we are providing a new
          solution to a problem that hasn't been addressed the way we have done
          it, it can be difficult for the users to grasp how the app works and
          make use of all its features.
        </p>
        <h5>What does success look like?</h5>
        <p>
          An amazing UI/UX experience which is able to fully utilise all of our
          features in a simple intuitive way that doesn't look and feel
          cluttered
        </p>
        <h4>Brainstorm</h4>
        <p>
          Following the stakeholder meeting we created a brainstorm to help
          visualise some key words from what we learnt about Flimpi.
        </p>
        <img src="images/flimpi1.png" alt="brainstorm" />
        <h4>Competitors analysis</h4>
        <img src="images/flimpi2.png" alt="competitor analysis" />
        <h4>Discussion guide and meeting</h4>
        <p>
          Prior to the stakeholder meeting we collectively put together a list
          of questions that were important for us to get a better understanding
          of Flimpi.
        </p>
        <p>Here are some of the questions we asked:</p>
        <h5>
          How central do you want gamification to be a part of your product?
        </h5>
        <p>
          The client wants gamification to be the main part of the app. They
          hope to gain users that come back for the fun gamified features.
        </p>
        <h5>What do you want your users to gain from using your product?</h5>
        <p>
          The client acknowledges that having separate apps for loyalty programs
          is often an app overload and can cause users to become
          &#39;;lazy&#39;; with using them. They hope that the users will
          benefit from having them all centralised on the one app so that they
          can be used to full potential. As well engaging in activities and
          campaigns run by brands a user will earn rewards discounts or Flimpi
          points etc.
        </p>
        <h5>What are the problems you are trying to solve?</h5>
        <p>
          The client hopes to boost brick and mortar businesses as he
          acknowledges that they don&#39;t have the same experience that online
          shops have. As for the end users he hopes to provide fun experiences
          while engaging in activities to earn Flimpi points or financial
          rewards.
        </p>
        <h5>How many types of challenges are there and what are they?</h5>
        <ul className="listStyle">
          <BiSolidLabel />
          <li>
            Raffle: Instant rewards, single location, events &#40;happy
            hour&#41;
          </li>
          <BiSolidLabel />
          <li>Scavenger hunt: Multi-location, journey task</li>
          <BiSolidLabel />
          <li>
            Quest: Multi-location, journey task with a narrative and theme
          </li>
          <BiSolidLabel />
          <li>
            Adventures: Free exploration of single or multiple locations,
            following clues in their own time
          </li>
          <BiSolidLabel />
          <li>
            Marathon: Long term engagement, based on regular check-ins or
            purchases with substantial rewards
          </li>
        </ul>
        <h5>Campaign outcomes</h5>
        <ul className="listStyle">
          <BiSolidLabel />
          <li>Financial</li>
          <BiSolidLabel />
          <li>Physical</li>
          <BiSolidLabel />
          <li>Status</li>
          <BiSolidLabel />
          <li>Informational</li>
        </ul>
        <h4>User research</h4>
        <h5>Research Goal:</h5>
        <p>
          A clear research goal is an important first step. We have to look at
          the brief as a whole and find a solid place to build our foundations.
          We have to ask ourselves, &#39;what are we ultimately interested in
          finding out about?&#39;.
          <p>We wanted to find out what people used their maps for.</p>
        </p>
        <p>
          <i>“What do you use map apps for?” </i>
        </p>
        <h5>Quantitative research:</h5>
        <p>
          Now that we have a research goal, we decided to circulate a screener
          survey. The purpose of this is two-fold. First, we are looking for
          people to interview. Second, to gather useful quantitative data around
          our research goal.
        </p>
        <ul className="listStyle">
          <BiSolidLabel />
          <li>
            <b>84%</b> of those we asked, stated that they use map related apps
            on their phones <b>&#39;often&#39;</b> or
            <b>&#39;very often&#39;</b>.
          </li>
          <BiSolidLabel />
          <li>
            When it comes to buying clothes and groceries, the
            <b>vast majority prefer to do it in person</b>, despite the
            prevalence of online shopping.
          </li>
          <BiSolidLabel />
          <li>
            Only <b>2 of our 44</b> respondents felt that
            <b>supporting local businesses wasn&#39;t important</b>.
          </li>
        </ul>
        <p>
          <i>
            Insight: There is an opportunity for map apps to improve customer
            engagement / acquisition for &#39;brick and mortar&#39; businesses.
          </i>
        </p>
        <img src="#" alt="Quantitative research" />
        <h4>User interview</h4>
        <h5>Qualitative research:</h5>
        <p>
          Using the survey participants who ‘passed’ our screener, we
          interviewed 21 people to gather qualitative data. We created a
          research guide around our research goal, while also bearing in mind
          the stakeholders vision:
        </p>
        <ul className="listStyle">
          <BiSolidLabel />
          <li>Map usage</li>
          <BiSolidLabel />
          <li>Geo-cacheing/scavenger hunting</li>
          <BiSolidLabel />
          <li>Advertising/offers</li>
          <BiSolidLabel />
          <li>Gamification</li>
          <BiSolidLabel />
          <li>Tourism</li>
          <BiSolidLabel />
          <li>Foot fall</li>
        </ul>
        <p>We were also looking out for useful:</p>
        <ul className="listStyle">
          <BiSolidLabel />
          <li>Observations</li>
          <BiSolidLabel />
          <li>Anecdotes</li>
          <BiSolidLabel />
          <li>Quotes</li>
          <BiSolidLabel />
          <li>Behaviours</li>
          <BiSolidLabel />
          <li>Emotions</li>
          <BiSolidLabel />
          <li>Reactions</li>
        </ul>
        <img src="#" alt="User Interview" />
        <h5>Affinity mapping:</h5>
        <p>
          After twenty-one 10-20 minute conversations, we had a lot of
          qualitative data to sort through. In order to make sense of it, we did
          an affinity mapping exercise. The purpose of which is to arrange the
          &#39;200+&#39; insights into commonalities and themes that can be the
          basis of idea generation.
        </p>
        <p>Some of the strongest findings included:</p>
        <ul className="listStyle">
          <BiSolidLabel />
          <li>
            Our interviewees valued in-person experiences highly, especially
            when on holiday
          </li>
          <BiSolidLabel />
          <li>
            Our interviewees like rewards and discounts but will only work for
            them if they are worthwhile
          </li>
          <BiSolidLabel />
          <li>
            Map apps are more useful than people realise. They are imbedded into
            their daily lives
          </li>
          <BiSolidLabel />
          <li>
            People are looking for distractions on their phones when they are
            travelling
          </li>
        </ul>
        <img src="#" alt="Affinity mapping" />
        <h4>Ideation</h4>
        <h5>Define</h5>
        <p>
          How might we enhance a day tripper&#39;s experience with discounts,
          rewards & activities. We brainstormed a few different problem
          statements and how might we statements and landed on this one based on
          our value proposition.
        </p>
        <h5>Ideate</h5>
        <p>
          Our next step was ideation, so we undertook a fun and creative
          exercise called crazy 8&#39;s to rapidly generate as many ideas as
          possible
        </p>
        <img src="#" alt="Affinity mapping" />
        <p>
          We chose our favourite ideas to generate further and our chosen idea
          was incentivising day tripper&#39;s with personalised and categorised
          rewards that they could unlock by completing certain challenges.
        </p>
        <p>
          Based on the interviewees, we target the main user as day-trippers and
          create a customer profile. Visualising those informations helps us to
          ask questions and perceive through users&#39; eyes, and find a more
          unique and effective strategy.
        </p>
        <p>
          After knowing more about our users, we create an empathy map to
          illustrate user attitudes and behaviours, and that guides us toward
          meaningful innovation.
        </p>
        <img src="#" alt="Emphaty map" />
      </main>
    </IconContext.Provider>
  );
}
export default Fimpli;
