import { useState } from "react";
import Project from "./Project";

import "./main.css";
import FinChair from "./caseStudy/FinChair";
import Fimpli from "./caseStudy/Fimpli";
import Leagueleaders from "./caseStudy/LeagueLeaders";

function Main() {
  const [expandedProject, setExpandedProject] = useState(null);
  const [displayLabel, setdisplayLabel] = useState(true);
  const [displayCaseStudy, setdisplaydisplayCaseStudy] = useState(null);
  const [projectTitle, setProjectTitle] = useState(true);

  function handleProjectClick(projectId) {
    setExpandedProject(projectId);
    setdisplayLabel(null);
    setdisplaydisplayCaseStudy(true);
    setProjectTitle(null);
  }
  function handleProjectClose() {
    setExpandedProject(null);
    setdisplayLabel(true);
    setdisplaydisplayCaseStudy(null);
    setProjectTitle(true);
  }

  return (
    <main>
      <h2>{projectTitle && "Projects"}</h2>
      <div id="projects">
        <Project
          id="leagueLeader"
          title="League Leaders"
          onClick={() => handleProjectClick("leagueLeader")}
          onCloseClick={() => handleProjectClose()}
          isExpanded={expandedProject === "leagueLeader"}
          shouldHide={expandedProject && expandedProject !== "leagueLeader"}
          imageHero={"./images/placeholder0.png"}
          lable={displayLabel}
          displayCaseStudy={displayCaseStudy}
          caseStudy={<Leagueleaders />}
        />
        <Project
          id="Fimpli"
          title="Fimpli"
          onClick={() => handleProjectClick("Fimpli")}
          onCloseClick={() => handleProjectClose()}
          isExpanded={expandedProject === "Fimpli"}
          shouldHide={expandedProject && expandedProject !== "Fimpli"}
          imageHero={"./images/placeholder0.png"}
          lable={displayLabel}
          displayCaseStudy={displayCaseStudy}
          caseStudy={<Fimpli />}
        />

        <Project
          id="FinChair"
          title="FinChair"
          onClick={() => handleProjectClick("FinChair")}
          onCloseClick={() => handleProjectClose()}
          isExpanded={expandedProject === "FinChair"}
          shouldHide={expandedProject && expandedProject !== "FinChair"}
          imageHero={"./images/placeholder0.png"}
          lable={displayLabel}
          displayCaseStudy={displayCaseStudy}
          caseStudy={<FinChair />}
        />
        <Project
          id="project1"
          title="project1"
          onClick={() => handleProjectClick("project1")}
          onCloseClick={() => handleProjectClose()}
          isExpanded={expandedProject === "project1"}
          shouldHide={expandedProject && expandedProject !== "project1"}
          imageHero={"./images/placeholder0.png"}
          lable={displayLabel}
          displayCaseStudy={displayCaseStudy}
          caseStudy={""}
        />
      </div>
    </main>
  );
}

export default Main;
