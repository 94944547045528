import SocialIcons from "./SocialIcons";
import Animation from "./Animation.js";

import "./Header.css";

function Header() {
  return (
    <>
      <header className="pageHeader">
        <SocialIcons />
        <div className="banner">
          <div className="title">
            <h1>Marco Di Summa</h1>
            <p>Digital Designer</p>
          </div>
        </div>
        <Animation className={"element"} id={"orange"} />
        <Animation className={"element"} id={"yellow"} />
        <Animation className={"element"} id={"blue"} />
        <p className="intro">
          I'm Marco Di Summa, an aspiring UX/UI Designer with a user-centered
          approach. My goal is to create interfaces that combine intuitive
          design and visual appeal, delivering seamless and delightful digital
          experiences for users.
        </p>
      </header>
    </>
  );
}

export default Header;
