import "./leagueLeaders.css";

function LeagueLeaders() {
  return (
    <div className="leagueLeaders">
      <h3>League leaders</h3>
      <p>Under development</p>
    </div>
  );
}
export default LeagueLeaders;
