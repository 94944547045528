import { CgArrowLeftO } from "react-icons/cg";

import React from "react";

function Project(props) {
  const handleXButtonClick = (event) => {
    console.log(event);
    event.stopPropagation(); // Prevent the click from propagating to the parent section
    props.onCloseClick();
  };

  return (
    <section
      className={`project ${
        props.isExpanded ? "open" : props.shouldHide ? "hidden" : ""
      }`}
      id={props.id}
      onClick={props.onClick}
    >
      {props.isExpanded && (
        <button className="close" onClick={handleXButtonClick}>
          <CgArrowLeftO />
        </button>
      )}
      {props.lable && (
        <div className="lable">
          <h3>{props.title}</h3>
          <img
            src="./images/placeholder0.png"
            alt="label image"
            className="labelImage"
          />
        </div>
      )}
      {props.displayCaseStudy && props.caseStudy}
    </section>
  );
}

export default Project;
